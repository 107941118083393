<template>
  <div
    class="badgeToast"
    v-on:click="onClick"
  >
    <img
      v-bind:src="badge.image"
      class="badgeToast__image"
    />

    <div class="badgeToast__content">
      <div class="badgeToast__title">
        {{ badge.name }}
      </div>

      <div class="badgeToast__link">
        {{ $t('badgeToast.linkLabel') }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { ModalBus } from '@/eventBus';
import confetti from '@/mixins/confetti';

const BadgeModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/badges/BadgeModal' /* webpackChunkName: "badgeModal" */),
}));

export default {
  mixins: [
    confetti,
  ],

  props: {
    badge: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onClick() {
      this.$emit('onClose');

      ModalBus.emit('open', {
        component: BadgeModal,
        modifiers: 'badge',
        props: { badge: this.badge },
      });

      this.giveMeConfetti();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.badgeToast {
  cursor: pointer;

  @include desktop {
    display: flex;
    align-items: center;
  }
}

.badgeToast__image {
  display: none;

  @include desktop {
    flex: 0 0 auto;
    display: block;
    margin: 0 rem(20px) 0 0;
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
}

.badgeToast__content {
  flex: 1 1 auto;
}

.badgeToast__title {
  @include label--bold;
  margin: 0 0 rem(3px) 0;

  @include desktop {
    @include small--bold;
    margin: 0 0 rem(4px) 0;
  }
}

.badgeToast__link {
  @include tiny--bold;
  color: $color-green;

  @include desktop {
    @include label--bold;
  }
}
</style>